<template>
  <v-container id="buyerGuide" tag="section" class="container">
    <div class="post-container">
      <figure class="figure">
        <div class="post-thumb">
          <div class="image-inset">
            <v-img :src="require(`@/assets/images/mahsa_selling.JPG`)" />
          </div>
        </div>
        <figcaption class="image-card-wrapper">
          <div class="image-card">
            <div class="image-subtitle-wrapper">
              <div class="image-subtitle">
                <h3>
                  Want to be prepared, educated, and know <strong><em>exactly</em></strong> what to expect when buying
                  your first home?
                </h3>
                <p>Do you also desire the freedom, privacy, and ability to grow long-term wealth that homeownership can
                  offer?</p>
                <p>Enter your details below to get immediate access to this FREE download!</p>
              </div>
            </div>
          </div>
        </figcaption>
      </figure>
    </div>
    <v-form v-if="!didSubmit" v-model="valid">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" md="2">
            <v-text-field v-model="firstName" :rules="nameRules" :counter="20" label="First name" required solo />
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="lastName" :rules="nameRules" :counter="20" label="Last name" required solo />
          </v-col>

          <v-col cols="12" md="2">
            <v-text-field v-model="email" :rules="emailRules" label="E-mail" required solo />
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-btn :disabled="!valid" color="success" class="mr-4" @click="submitForm">
            Submit
          </v-btn>
        </v-row>
      </v-container>
    </v-form>

    <v-col v-if="didSubmit">
      <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
        <base-subheading class="mb-3">
          Thank you
        </base-subheading>

        <p>
          Download my Buyer's Guide by clicking <b><span @click="downloadGuide()">HERE</span></b>
        </p>
      </v-responsive>
    </v-col>
  </v-container>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/database'

export default {
  name: 'BuyerGuideComponent',

  data: () => ({
    database: firebase.database(),
    didSubmit: false,
    valid: false,
    firstName: '',
    lastName: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 20 || 'Name must be less than 20 characters',
    ],
    email: '',
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],
  }),
  methods: {
    submitForm() {
      this.database
        .ref('users/')
        .push({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          buyerGuide: true,
        })
        .then(() => {
          this.didSubmit = true
        })
    },
    downloadGuide() {
      const storageRef = firebase.storage().ref()
      // Create a reference to the file we want to download
      var guideRef = storageRef.child('Sharp TV Manual.pdf')

      // Get the download URL
      guideRef.getDownloadURL()
        .then((url) => {
          // Insert url into an <img> tag to "download"
          console.log(url)
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break
            case 'storage/canceled':
              // User canceled the upload
              break

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break
          }
        })
    },
  },
}
</script>
<style>
.post-container {
  margin: 20px 20px 0 0;
  overflow: auto;
}

.post-thumb {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-family: interface;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.01em;
  text-transform: none;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  font-size: calc(1.05 * 1rem);
  color: #000;
  position: relative;
  max-width: initial !important;
  overflow: visible;
  width: 40%;
}

.image-inset {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-family: interface;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.01em;
  text-transform: none;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  font-size: calc(1.05 * 1rem);
  color: #000;
  min-height: 1px;
  position: relative !important;
  width: 120%;
  left: -20%;
}

.post-content {
  margin-left: 210px;
}

.post-title {
  font-weight: bold;
  font-size: 200%;
}

.figure {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-family: interface;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.01em;
  text-transform: none;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  font-size: calc(1.05 * 1rem);
  color: #000;
  margin: 0;
  transform: translatez(0);
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.image-card-wrapper {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-family: interface;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.01em;
  text-transform: none;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  font-size: calc(1.05 * 1rem);
  color: #000;
  z-index: 2;
  pointer-events: none;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.image-card {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-family: interface;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.01em;
  text-transform: none;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  font-size: calc(1.05 * 1rem);
  color: #000;
  padding: 10%;
  box-sizing: border-box;
  background-color: #ffffff;
  pointer-events: auto;
  width: 100%;
  margin-right: 0%;
}

.image-subtitle-wrapper {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-family: interface;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.01em;
  text-transform: none;
  line-height: 1.7;
  -webkit-font-smoothing: antialiased;
  font-size: calc(1.05 * 1rem);
  color: #000;
  pointer-events: auto;
  width: 100%;
  text-align: left !important;
}

.image-subtitle {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-family: interface;
  font-weight: 400;
  font-style: normal;
  letter-spacing: -.01em;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  color: #000;
  pointer-events: auto;
  text-align: left !important;
  min-width: 100%;
  font-size: inherit !important;
  line-height: inherit !important;
}

h3 {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  -webkit-font-smoothing: antialiased;
  pointer-events: auto;
  text-align: left !important;
  font-weight: 800;
  font-style: normal;
  letter-spacing: .09em;
  text-transform: none;
  line-height: 1.344;
  font-size: calc(2 * 1rem);
  margin: 2rem 0;
  font-family: 'Inter-Medium';
  color: #000;
  margin-top: 0;
}

p {
  -webkit-text-size-adjust: 100%;
  overflow-wrap: break-word;
  --sqs-site-gutter: 3vw;
  --sqs-mobile-site-gutter: 6vw;
  --sqs-site-max-width: 1800px;
  font-style: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  pointer-events: auto;
  text-align: left !important;
  font-family: 'Inter-Regular';
  font-weight: 400;
  letter-spacing: 0em;
  line-height: 1.5em;
  font-size: calc(1.2 * 1rem);
  margin: 1rem 0;
  color: #000;
  white-space: pre-wrap;
}
</style>
